var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"l-welcome l-guide"},[_c('h1',{staticClass:"l-welcome-ttl"},[_vm._v("講師登録から出品までの流れ")]),_c('ol',{staticClass:"l-step"},[_c('li',{staticClass:"l-section"},[_c('i',{staticClass:"no"},[_vm._v("Step")]),_c('div',{staticClass:"flex lp-section--inner"},[_vm._m(0),_c('div',{staticClass:"lp-section--img object-center"},[_c('button',{staticClass:"btn-register btn-radius",on:{"click":_vm._register}},[_vm._v("会員登録"),_c('span',{staticClass:"sub"},[_vm._v("（無料）")])])])])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"figcaption"},[_c('h2',{staticClass:"lp-section--title"},[_vm._v("会員登録")]),_c('p',[_vm._v("画面右上の「会員登録」よりニックネーム、メールアドレス、パスワード、電話番号を入力して登録。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"l-section"},[_c('i',{staticClass:"no"},[_vm._v("Step")]),_c('div',{staticClass:"flex lp-section--inner"},[_c('div',{staticClass:"figcaption"},[_c('h2',{staticClass:"lp-section--title"},[_vm._v("プロフィール設定")]),_c('p',[_vm._v("画面右上の「マイページ」をクリックし、右カラムの「自己紹介編集」より自己紹介を入力します。"),_c('br'),_vm._v("ご自身の経歴や対応可能な相談内容などをご入力ください。")])]),_c('div',{staticClass:"lp-section--img"},[_c('figure',[_c('img',{attrs:{"src":"/img/guide/reg02.png","alt":"プロフィール設定"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"l-section"},[_c('i',{staticClass:"no"},[_vm._v("Step")]),_c('div',{staticClass:"flex lp-section--inner"},[_c('div',{staticClass:"figcaption"},[_c('h2',{staticClass:"lp-section--title"},[_vm._v("講師に応募する")]),_c('p',[_vm._v("画面上部のメニュー右側の「メニューを登録（相談員）」またはトップページ下部「講師に応募する」より「レッスンを出品したい場合」のページに遷移。「講師に応募する」ボタンから登録フォームに移動します。 ")]),_c('p',{staticClass:"at"},[_vm._v("※"),_c('a',{attrs:{"href":"/terms","target":"_blank","rel":"noopener"}},[_vm._v("利用規約")]),_vm._v("および"),_c('a',{attrs:{"href":"/privacy-policy","target":"_blank","rel":"noopener"}},[_vm._v("個人情報の取り扱いについて")]),_vm._v("をご一読ください。")])]),_c('div',{staticClass:"lp-section--img"},[_c('figure',[_c('img',{attrs:{"src":"/img/guide/reg03.png","alt":"講師に応募する"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"l-section"},[_c('i',{staticClass:"no"},[_vm._v("Step")]),_c('div',{staticClass:"flex lp-section--inner"},[_c('div',{staticClass:"figcaption"},[_c('h2',{staticClass:"lp-section--title"},[_vm._v("講師登録フォーム")]),_c('p',[_vm._v("氏名、郵便暗号、都道府県、以降の住所、自己紹介、得意ジャンル、および可能な限りSNS情報を入力いただき、「送信」ボタンをクリックします。")]),_c('p',[_vm._v("送信ボタンをクリック後、決済サービス「Stripe」の認証画面に遷移します。")])]),_c('div',{staticClass:"lp-section--img"},[_c('figure',[_c('img',{attrs:{"src":"/img/guide/reg04.png","alt":"講師登録フォーム"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"l-section"},[_c('i',{staticClass:"no"},[_vm._v("Step")]),_c('div',{staticClass:"flex lp-section--inner"},[_c('div',{staticClass:"figcaption-column"},[_c('h2',{staticClass:"lp-section--title"},[_vm._v("Stripeによる本人認証")]),_c('p',[_vm._v("個人情報と振込用銀行口座をご登録いただきます。免許書などの身分証明書をご準備いただく必要があります。")]),_c('p',{staticClass:"at"},[_vm._v("※Stripe（"),_c('a',{attrs:{"href":"https://stripe.com/jp","target":"_blank","rel":"noopener"}},[_vm._v("https://stripe.com/jp")]),_vm._v("）は、世界中の数百万の企業が導入しているオンライン決済サービスです。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"l-section"},[_c('i',{staticClass:"no"},[_vm._v("Step")]),_c('div',{staticClass:"flex lp-section--inner"},[_c('div',{staticClass:"figcaption-column"},[_c('h2',{staticClass:"lp-section--title"},[_vm._v("審査中")]),_c('p',[_vm._v("Stripeでの審査完了後に、入力内容をもとに、編集部で講師審査させていただきます。審査には最大7営業日を頂戴しております。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"l-section"},[_c('i',{staticClass:"no"},[_vm._v("Step")]),_c('div',{staticClass:"flex lp-section--inner"},[_c('div',{staticClass:"figcaption-column"},[_c('h2',{staticClass:"lp-section--title"},[_vm._v("審査完了後出品機能が付与")]),_c('p',[_vm._v("無事講師登録が完了したら、サポートセンターよりメールにて通知します。")]),_c('p',[_vm._v("出品（相談受付／レッスン）については、メールに記載されているURLおよびページヘッダー「メニューを登録（相談員）」、マイページの出品一覧より登録してください。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"l-section"},[_c('i',{staticClass:"no"},[_vm._v("Step")]),_c('div',{staticClass:"flex lp-section--inner"},[_c('div',{staticClass:"figcaption"},[_c('h2',{staticClass:"lp-section--title"},[_vm._v("メニューを登録/マイページの出品一覧から相談を登録")]),_c('p',[_vm._v("タイトル、本文、価格、サムネイル画像、カテゴリ（親/子）、受付枠数を入力し、ステータスを「販売中」に設定して「更新」をクリックすることで出品登録が完了します。")]),_c('p',{staticClass:"at"},[_vm._v(" ※登録価格からStripe決済手数料およびサービス利用料を差し引いた70%を報酬として、Stripeにご登録いただいた銀行口座へお振込みいたします。レッスン完了後、前週火曜日から翌週月曜日までのレッスン費用を金曜日に送金致します。"),_c('br'),_vm._v(" (銀行の処理時間によって、入金には数営業日かかる場合があります) ")])]),_c('div',{staticClass:"lp-section--img"},[_c('figure',[_c('img',{attrs:{"src":"/img/guide/reg08.png","alt":"登録画面"}})])])])])
}]

export { render, staticRenderFns }